/* eslint-disable camelcase */
const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        blogType: [],
        newsAll: {
            list: [],
            current: 1,
            total: 1,
        },
        newsOfType: {
            list: [],
            current: 1,
            total: 1,
        },
        newsDetail: {},
    }),

    getters: {
        BlogType: (state) => {
            return state.blogType;
        },
        NewsAll: (state) => state.newsAll,
        NewsOfType: (state) => state.newsOfType,
        NewsDetail: (state) => state.newsDetail,
    },

    actions: {
        req_getBlogType: ({ commit }) => {
            axios.get(`api/v1/blog`).then((res) => {
                if (res) {
                    commit('SET_BLOG_TYPE', res);
                }
            });
        },
        req_getNewsOfType: ({ commit }, input) => {
            axios
                .get('api/v1/article', {
                    params: {
                        id: input,
                    },
                })
                .then((res) => {
                    if (res) {
                        commit('SET_NEWS_OF_TYPE', res);
                    }
                });
        },
        req_getNewsAll: ({ commit }, input) => {
            axios
                .get('api/v1/article', {
                    params: {
                        page: input.page,
                    },
                })
                .then((res) => {
                    if (res) {
                        commit('SET_NEWS_ALL', res);
                    }
                });
        },
        req_getNewsDetail: ({ commit }, input) => {
            axios
                .get('api/v1/article/detail', { params: { id: input } })
                .then((res) => {
                    if (res) {
                        commit('SET_NEWS_DETAIL', res);
                    }
                });
        },
    },

    mutations: {
        SET_BLOG_TYPE: (state, data) => {
            state.blogType = data.data;
        },

        SET_NEWS_OF_TYPE: (state, data) => {
            console.log(data);
        },
        SET_NEWS_ALL: (state, news) => {
            const { data, last_page, current_page } = news;
            state.newsAll.current = current_page;
            state.newsAll.total = last_page;
            if (current_page !== 1) {
                state.newsAll.list.push(data);
            } else {
                state.newsAll.list = data;
            }
        },
        SET_NEWS_DETAIL: () => {
        },
    },
};
