import Vue from 'vue';
import store from '@/store';
import { BootstrapVue } from 'bootstrap-vue';
import VueClipboard from 'vue-clipboard2';
import VueToastr from 'vue-toastr';
import moment from 'moment';

import App from './App.vue';
import router from './router';
import './design/scss/app.scss';
// import fontawesome
import '@/assets/font-awesome/css/duotone.min.css';
import '@/assets/font-awesome/css/fontawesome.min.css';
import '@/assets/font-awesome/css/solid.min.css';
import '@/assets/font-awesome/css/regular.min.css';

import '@/assets/font-awesome/js/fontawesome';
import '@/assets/font-awesome/js/solid';
import '@/assets/font-awesome/js/duotone';
import '@/assets/font-awesome/js/regular';

import i18n from './i18n';

Vue.use(VueClipboard);
VueClipboard.config.autoSetContainer = true;

Vue.use(BootstrapVue);

Vue.use(VueToastr, {
    defaultTimeout: 3000,
    defaultPosition: 'toast-top-right',
    defaultClassNames: ['animate__animated ', 'animate__bounceInRight'],
});

Vue.mixin({
    methods: {
        getDateTime(unix, lang) {
            if (lang === 'vi') {
                return moment(unix).utcOffset('+00:00').format('DD/MM/YYYY');
            }
            return moment(unix).utcOffset('+00:00').format('MMM D, YYYY');
        },
    },
});

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
