<template>
    <footer id="footer">
        <b-container>
            <b-row>
                <b-col cols="12" md="6" lg="3">
                    <img
                        src="~@/assets/images/logo/logo.png"
                        alt=""
                        width="100%"
                    />
                </b-col>
                <b-col cols="12" md="6" lg="3">
                    <div class="box-footer">
                        <div class="title">Hero Book</div>
                        <ul>
                            <li>
                                <a
                                    href="https://herobook.io/home"
                                    target="_blank"
                                >
                                    {{ $t('footer.home') }}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://herobook.io/project"
                                    target="_blank"
                                >
                                    {{ $t('footer.project') }}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://herobook.io/token-metric"
                                    target="_blank"
                                >
                                    HBG Token
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://herobook.io/Whitepaper%20ENG.pdf?v=pi"
                                    target="_blank"
                                    >White Paper
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://whitepaper.herobook.io/"
                                    target="_blank"
                                >
                                    {{ $t('footer.doc') }}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://marketplace.herobook.io/"
                                    target="_blank"
                                >
                                    Marketplace
                                </a>
                            </li>
                        </ul>
                    </div>
                </b-col>
                <b-col cols="12" md="6" lg="3">
                    <div class="box-footer">
                        <div class="title">Menu</div>
                        <ul>
                            <li
                                v-for="sub in BlogType"
                                :key="`footer-menu-${sub.blog_id}`"
                            >
                                <router-link
                                    :to="{
                                        name: sub.components,
                                        params: { locale: $i18n.locale },
                                    }"
                                >
                                    {{ sub.blog_name }}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </b-col>
                <b-col cols="12" md="6" lg="3">
                    <div class="box-footer">
                        <div class="title">Contact</div>
                        <ul>
                            <li>
                                <span>
                                    {{ $t('footer.company') }}
                                </span>
                            </li>
                            <li>
                                <span>
                                    {{ $t('footer.address') }}
                                </span>
                            </li>
                            <li>
                                <span> {{ $t('footer.code') }} </span>
                            </li>
                            <li>
                                <a href="emailto:support@herobook.io ">
                                    support@herobook.io
                                </a>
                            </li>
                            <li>
                                <a href="#" class="buy-token">
                                    {{ $t('footer.buy') }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <a href="#"><button class="btn-top">Top</button></a>
    </footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            BlogType: 'blog/BlogType',
        }),
    },
    watch: {
        '$i18n.locale': {
            handler() {
                this.$store.dispatch({ type: 'blog/req_getBlogType' });
            },
        },
    },
};
</script>

<style lang="scss">
footer#footer {
    background-image: url('~@/assets/images/background/s3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 2000px #001326a6;
    padding: 20px 0;
    position: relative;
    .container {
        position: relative;
        z-index: 10;
    }
    &.bgAirdrop {
        background: none;
        background-color: #001326 !important;
    }
    .buy-token {
        font-family: 'HHBI';
        font-size: 1.3em;
        color: #fff;
        text-shadow: 2px 0 0 rgba(0, 252, 245, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        text-decoration: none !important;
        background-size: 100%100%;
        background-repeat: no-repeat;
        letter-spacing: 2px;
        overflow: hidden;
        position: relative;
        margin: 35px 0 0;
        width: 230px;
        height: 64.5px;
        background-image: url(~@/assets/images/mockup/box2.png);
        &:hover {
            filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.24));
            transition: all ease-in 0.1s;
            transform: scale(1.03);
        }
    }
    .title {
        font-family: 'HHBI';
        font-size: 1.6em;
        color: #00fff8;
        line-height: 120%;
        margin-bottom: 15px;
        text-align: left;
    }
    .box-footer {
        padding: 0 15%;
        margin: auto;
        ul {
            list-style: none;
            padding: 0;
            li {
                margin-bottom: 10px;
                span,
                a {
                    color: #fff;
                    font-size: 1.1em;
                    font-weight: 500;
                }
            }
        }
    }
    .btn-top {
        position: absolute;
        bottom: 20px;
        right: 20px;
        background: none;
        border: 1px solid #00fff8;
        color: #00fff8;
        padding: 10px;
        border-radius: 50%;
        z-index: 10;
    }
    @media (max-width: 767px) {
        .title {
            text-align: center;
        }
        .box-footer {
            padding: 10px 25px;
            text-align: center;
        }
        .buy-token {
            margin: 35px auto 0;
        }
    }
}
</style>
