// import store from '@/store';

import Layout from './layouts/Master.vue';
import i18n from '../i18n';

export default [
    {
        path: '*',
        redirect: {
            name: 'All',
            params: { type: 'all-articles', locale: i18n.locale },
        },
    },
    {
        path: '/:locale',
        component: Layout,
        name: 'Blog',
        redirect: {
            name: 'All',
            params: { type: 'all-articles', locale: 'en' },
        },
        children: [
            {
                path: 'all-articles',
                name: 'All',
                component: () => import('./views/All/Index.vue'),
            },
            {
                path: 'announcements',
                name: 'Announcements',
                component: () => import('./views/Announcements/Index.vue'),
            },
            {
                path: 'project-updates',
                name: 'Updates',
                component: () => import('./views/Update/Index.vue'),
            },
            {
                path: 'platform',
                name: 'Platform',
                component: () => import('./views/Platform/Index.vue'),
            },
            {
                path: 'technology',
                name: 'Technology',
                component: () => import('./views/Technology/Index.vue'),
            },
            {
                path: 'tutorials',
                name: 'Tutorials',
                component: () => import('./views/Tutorials/Index.vue'),
            },
            {
                path: 'press-release',
                name: 'Release',
                component: () => import('./views/Release/Index.vue'),
            },
            {
                path: '/:locale/:type/:id',
                name: 'Detail',
                component: () => import('@/components/shared/NewsDetail.vue'),
            },
        ],
    },
    {
        // <---------------
        path: '*',
        redirect: {
            name: 'All',
            params: { type: 'all-articles', locale: i18n.locale },
        },
    },
    // {
    //   path: "/404",
    //   name: "404",
    //   component: require("@/views/utility/404").default,
    // },
    // // Redirect any unmatched routes to the 404 page. This may
    // // require some server configuration to work in production:
    // // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    // {
    //   path: "*",
    //   redirect: "404",
    // },
];
